import { siteConstants } from "../../assets/constants/site.constants";
import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import "./footer.css";

const Footer = ({ name, nav, socialLinks, style }) => {
  return (
    <section id="footer-section" className={style.footerSection}>
      <div className={style.footerRow}>
        <p>Copyright &copy; loretta3 2024</p>
        <div className={style.footerNavLinks}>
          <div className="contact-col">
            <div
              className="social-icon-wrapper"
              aria-label={`Redes Sociales de ${name}`}
            >
              {socialLinks &&
                Object.keys(socialLinks).map((item, i) => {
                  const link =
                    socialLinks[item].length > 0 ? (
                      <a
                        key={`${i}`}
                        href={socialLinks[item]}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={`${item} de ${name}`}
                      >
                        <i className={`fa fa-${item} my-float`}></i>
                      </a>
                    ) : (
                      <></>
                    );

                  return link;
                })}
            </div>
          </div>
        </div>
        <div className="footer-nav-links">
          <ul>
            {nav &&
              Object.keys(nav).map((item) => (
                <li key={item}>
                  <a href={`#${nav[item].href}`}>{nav[item].label}</a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;
