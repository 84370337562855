// import orange from "../assets/images/orange-slice.png";
// import "./post.css";s
import { useEffect } from "react";
import styles from "./post.module.css";

const Post = ({ post }) => {
  useEffect(() => {
    // document.body.classList.add(styles.post);
    // return () => {
    //   document.body.classList.remove(styles.post);
    // };
  }, []);

  return (
    <>
      <section id="post-section" className={styles.post}>
        <div>
          <div className={styles.postInfo}>
            <span id="post-one" className={styles.postOne}>
              <h1>{post.post}</h1>
            </span>
            <span id="post-two" className={styles.postTwo}>
              <h2>
                <span className={styles.tittle}>{post.postOne}</span>&nbsp;
                {post.postTwo}
              </h2>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Post;
