// src/components/LogoCanvas.js
import React, { useEffect, useRef } from "react";

const Post = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Dibujar texto "ARTESANAS"
    ctx.font = "30px Arial";
    ctx.fillStyle = "red";
    ctx.fillText("ARTESANIAS", 150, 50);

    // Dibujar el armadillo
    ctx.beginPath();
    ctx.arc(250, 100, 40, Math.PI, 0, false); // Cuerpo
    ctx.lineWidth = 5;
    ctx.strokeStyle = "red";
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(250, 100, 30, Math.PI, 0, false); // Primer segmento
    ctx.lineWidth = 5;
    ctx.strokeStyle = "red";
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(250, 100, 20, Math.PI, 0, false); // Segundo segmento
    ctx.lineWidth = 5;
    ctx.strokeStyle = "red";
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(250, 100, 10, Math.PI, 0, false); // Tercer segmento
    ctx.lineWidth = 5;
    ctx.strokeStyle = "red";
    ctx.stroke();
    ctx.closePath();

    // Dibujar texto "ARMADILLO"
    ctx.font = "30px Arial";
    ctx.fillStyle = "red";
    ctx.fillText("ARMADILLO", 150, 150);
  }, []);

  return <canvas ref={canvasRef} width={500} height={200}></canvas>;
};

export default Post;
