import toggleMenu from "./toggleMenu";
import "./navbar.css";

const NavBar = ({ nav }) => {
  return (
    <header className="sticky-bg">
      <nav>
        <div className="nav-links">
          <i className="fa fa-bars" onClick={toggleMenu}></i>
          {nav["home"] && (
            <a href={`#${nav["home"].href}`} className="hero-link">
              {nav["home"].label}
            </a>
          )}

          <div id="nav-links-sub">
            <ul>
              {nav &&
                Object.keys(nav)
                  .filter((item) => item !== "home")
                  .map((item) => (
                    <li key={item}>
                      <a href={`#${nav[item].href}`}>{nav[item].label}</a>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
