import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";

export const fetch = createAsyncThunk(
  `${siteConstants.CUST_ARMADILLO}/fecth`,
  async (payload) => {
    return [];
  }
);

// src/reducers/rootReducer.js
const initialState = {
  name: "Artesanías Armadillo",

  id: "artesanias-armadillo",
  name: "Artesanías Armadillo",
  type: "Artesanias",
  title: "Correaje y Cinturones de Cuero Hechos a Mano",
  seo: {
    title:
      "Artesanías Armadillo - Cinturones y Correajes de Cuero Artesanales Hechos a Mano",
    description:
      "Compra correas y cinturones de cuero personalizados, hechos a mano con materiales de alta calidad. Durabilidad y estilo únicos en Artesanías Armadillo.",
    keywords:
      "correas de cuero, cinturones personalizados, artesanía en cuero, productos artesanales Bogotá, cinturones de cuero hechos a mano, artículos de cuero Bogotá, correas de cuero personalizadas, cuero legítimo, cinturones de cuero Cundinamarca, artesanías colombianas, productos hechos a mano Bogotá, accesorios de cuero, correas de cuero Bogotá, cinturones de cuero Cundinamarca, artesanía local Bogotá, regalos personalizados cuero",
    url: "https://loretta3.com/landing/artesanias-armadillo",
    image: "https://loretta3.com/images/armadillo.png",
    robots: "index, follow",
    author: "Loretta3",
  },

  nav: {
    home: { href: "post-section", label: "Artesanias Armadillo" },
    about: { href: "about-us", label: "Productos" },
    // products: { href: "products", label: "Servicios" },
    // contact: { href: "contact", label: "Contacto" },
  },
  post: {
    post: "Armadillo",
    postOne: "Arte",
    postTwo: "sanias",
  },
  about: {
    "Nuestra Historia": {
      desc: "Artesanías Armadillo nace con el objetivo de preservar y celebrar la tradición de la marroquinería artesanal. Nos especializamos en la creación de correas y cinturones de cuero hechos a mano, fusionando técnicas ancestrales con diseños contemporáneos. Cada pieza que fabricamos lleva consigo una historia, el esfuerzo de artesanos comprometidos con la calidad y el detalle, buscando siempre ofrecer lo mejor de nuestra cultura a través de productos únicos y auténticos.",
    },
    "Nuestra Misión": {
      desc: "En Artesanías Armadillo, nuestro compromiso es ofrecer productos excepcionales, fabricados con materiales de la más alta calidad. Utilizamos cuero legítimo que garantiza resistencia, durabilidad y estética. Cada cinturón y correa es confeccionado a mano con esmero, para brindar a nuestros clientes no solo un accesorio funcional, sino también una pieza de arte que perdure con el tiempo. Queremos que cada cliente sienta el valor y el amor que ponemos en cada una de nuestras creaciones.",
    },
    "Nuestra Promesa": {
      desc: "Nos enorgullece trabajar de manera ética y responsable, utilizando materiales sostenibles y promoviendo prácticas de comercio justo. Personalizamos cada producto según las necesidades y gustos de nuestros clientes, ofreciendo artículos de cuero que no solo son robustos y funcionales, sino también exclusivos. En Artesanías Armadillo, creemos que cada pieza debe ser única y ajustarse perfectamente al estilo personal de quien la lleve. Si buscas un accesorio de cuero que combine tradición, calidad y diseño, tenemos lo que necesitas.",
    },
  },
  products: [
    {
      id: 1,
      name: "Correas para Perros",
      description:
        "Hechas a mano con cuero legítimo, resistentes y personalizables.",
      image: "correas-perros.jpg",
    },
    {
      id: 2,
      name: "Cinturones Personalizados",
      description: "Elige el diseño que más te guste, adaptado a tu estilo.",
      image: "correas-cinturones.jpg",
    },
    {
      id: 3,
      name: "Accesorios de Cuero",
      description:
        "Productos artesanales para tu día a día, de gran durabilidad.",
      image: "accesorios-cuero.jpg",
    },
  ],
  theme: {
    primaryColor: "#001c30",
    secondaryColor: "#230043",
    backgroundColor: "#e3e3d0",
    textColor: "#212529",
  },
  company: {
    name: "Artesanias Armadillo",
    whatsapp: "573011911727",
  },
  socialLinks: {
    facebook: "https://www.facebook.com/artesaniasarmadillo",
    instagram: "https://www.instagram.com/artesaniasarmadillo/",
    linkedin: "",
    twitter: "",
    youtube: "",
    tiktok: "",
  },
  // themeId: "theme_loretta3",
  // companyId: "company_loretta3",
  // socialLinksId: "social_loretta3",
  catalogId: "catalog_loretta3",

  customers: [
    {
      name: "Ashka Soluciones Financieras",
      title: "Ashka Soluciones Financieras - Créditos para Pensionados",
      description:
        "Encuentra la mejor opción de crédito para pensionados y empleados públicos con Ashka Soluciones Informáticas. Asesoría personalizada y la mejor oferta en créditos.",
      keywords:
        "créditos hipotecarios, créditos libranza, financiación, pensionados, empleados públicos",
      url: "https://loretta3.com/landing/ashka-soluciones-financieras",
      image: "https://loretta3.com/images/cust/ashka.png",
      robots: "index, follow",
      author: "Loretta3",
    },
    {
      name: "Ancestral Café Galería",
      title: "Ancestral Café Galería - Café, Postres y Arte en Zipaquirá",
      description:
        "Visita Ancestral Café Galería en el centro histórico de Zipaquirá y disfruta de nuestros postres, café, libros y arte en un ambiente único.",
      keywords:
        "café, postres, arte, libros, Zipaquirá, café en Zipaquirá, galería de arte",
      url: "https://loretta3.com/landing/ancestral-cafe-galeria",
      image: "https://loretta3.com/images/ancestral.jpg",
      robots: "index, follow",
      author: "Loretta3",
    },
    {
      name: "Artesanías Armadillo",
      title:
        "Artesanías Armadillo - Correaje y Cinturones de Cuero Hechos a Mano",
      description:
        "Descubre las correas de cuero personalizadas hechas a mano de Artesanías Armadillo. Calidad y durabilidad garantizadas.",
      keywords:
        "correas de cuero, cinturones, artesanía, productos personalizados, cuero legítimo",
      url: "https://loretta3.com/landing/artesanias-armadillo",
      image: "https://loretta3.com/images/armadillo.jpg",
      robots: "index, follow",
      author: "Loretta3",
    },
  ],
  message: null,
  loading: false,
  error: null,
  message: null,
  loading: false,
  error: null,
};

const armadilloSlice = createSlice({
  name: siteConstants.CUST_ARMADILLO,
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      });
  },
});

export const { init } = armadilloSlice.actions;
export default armadilloSlice.reducer;
