import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";
import fire from "../fire";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  setDoc,
  deleteDoc,
  doc,
  orderBy,
  // setDoc,
  // onSnapshot,
  query,
  where,
  // orderBy,
  limit,
  getDoc,
  // getDoc,
  // startAt,
  // endAt,
} from "firebase/firestore";

export const fetch = createAsyncThunk(
  `${siteConstants.LORETTA3}/fecth`,
  async (payload) => {
    return [];
  }
);

export const objAdded = createAsyncThunk(
  `${siteConstants.LORETTA3}/objAdded`,
  async (payload) => {
    // payload.createdAt = new Date();

    const db = getFirestore(fire.auth().app);
    const objs = await addDoc(collection(db, "sites"), payload, {
      merge: true,
    }).then((snap) => {
      return {
        ...payload,
        // createdAt: formatDateDDMMYYYHHMM(payload.createdAt),
        id: snap.id,
      };
    });
    return objs;
  }
);

export const objUpdated = createAsyncThunk(
  `${siteConstants.LORETTA3}/objUpdated`,
  async (payload) => {
    const { coll, obj } = payload;
    const db = getFirestore(fire.auth().app);
    // let createdAt_ = obj.createdAt;
    delete obj.createdAt;
    const docRef = doc(db, coll, obj.id);
    const objs = await setDoc(docRef, obj, { merge: true })
      .then((o) => {
        return {
          ...obj,
          // createdAt: createdAt_,
          // updatedAt: formatDateDDMMYYYHHMM(payload.updatedAt),
        };
      })
      .catch((e) => {
        throw e;
      });
    return objs;
  }
);

const initialState = {
  message: null,
  loading: false,
  error: null,
};

const siteSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })
      .addCase(objAdded.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(objAdded.fulfilled, (state, action) => {
        // state.obj = null;
        state.loading = false;
        state.info = "Registro exitoso";
      })
      .addCase(objAdded.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      });
  },
});

export const { init } = siteSlice.actions;
export default siteSlice.reducer;
