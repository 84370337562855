import { useSelector } from "react-redux";
import { whatsAppBt } from "../../assets/util/forms.util.js";
import { Container } from "react-bootstrap";
import SEO from "../../components/common/SEO.js";
import NavBar from "../../components/common/NavBar.js";
import About from "../../components/common/About.js";
import Post from "./Post.js";
import { useEffect } from "react";
import Contact from "../../components/common/Contact.js";
import Footer from "../../components/common/Footer.js";

import styles from "./site.module.css";

const ArtesaniasArmadilloHome = () => {
  useEffect(() => {}, []);

  const { name, seo, nav, socialLinks, post, about, company } = useSelector(
    (state) => state.armadillo
  );

  return (
    <Container id="armadillo-home" className={styles.armadilloHome}>
      <SEO seo={seo} />
      <NavBar nav={nav} />
      <Post post={post} />
      <About about={about} style={styles.aboutUs} />
      <Footer nav={nav} socialLinks={socialLinks} name={name} style={styles} />
      {whatsAppBt(company)}
    </Container>
  );
};

export default ArtesaniasArmadilloHome;
