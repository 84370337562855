import { QRCode } from "react-qrcode-logo";
import { useSelector } from "react-redux";
import { whatsAppBt } from "../../assets/util/forms.util.js";

import styles from "./site.module.css";

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import SEO from "../../components/common/SEO";
import NavBar from "../../components/common/NavBar";
import Post from "./Post";
import MenuList from "../../components/common/MenuList";
import Footer from "../../components/common/Footer";
import QRCodes from "../../components/QRCodes.js";
import MenuBody from "../../components/menu/MenuBody.js";

const AncestralHome = () => {
  const {
    name,
    menu,
    seo,
    nav,
    slogan,
    socialLinks,
    post,
    company,
    loading,
    message,
    error,
  } = useSelector((state) => state.ancestral);
  return (
    <Container id="ancestral-home" className={styles.ancestralHome}>
      <SEO seo={seo} />
      <NavBar nav={nav} />
      <Post post={post} />

      {/* <MenuBody menu={menu} /> */}

      <MenuList menu={menu} slogan={slogan} />
      <Footer nav={nav} socialLinks={socialLinks} name={name} style={styles} />
      {whatsAppBt(company)}
      {/* <QRCodes /> */}
    </Container>
  );
};

export default AncestralHome;
