import { QRCode } from "react-qrcode-logo";
import { useSelector } from "react-redux";
import { whatsAppBt } from "../../assets/util/forms.util.js";

import styles from "./site.module.css";

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import SEO from "../../components/common/SEO";
import NavBar from "../../components/common/NavBar";
import Post from "./Post";
import MenuList from "../../components/common/MenuList";
import Footer from "../../components/common/Footer";

const LaPringamosaHome = () => {
  // useEffect(() => {
  //   document.body.classList.add(styles.pringamosaHome);
  //   return () => {
  //     document.body.classList.remove(styles.pringamosaHome);
  //   };
  // }, []);

  const {
    name,
    menu,
    seo,
    nav,
    slogan,
    socialLinks,
    post,
    company,
    loading,
    message,
    error,
  } = useSelector((state) => state.pringamosa);
  return (
    <Container id="la-pringamosa-home" className={styles.pringamosaHome}>
      <SEO seo={seo} />
      <NavBar nav={nav} />
      <Post post={post} />
      <MenuList menu={menu} slogan={slogan} />
      <Footer nav={nav} socialLinks={socialLinks} name={name} style={styles} />
      {whatsAppBt(company)}
    </Container>
  );
};

export default LaPringamosaHome;
