import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Home from './features/web/Home';
import { Provider, useSelector } from "react-redux";
import { scrollTop, whatsAppBt } from "./assets/util/forms.util";
import store from "./store";
import PrivacyPolicy from "./components/PrivacyPolicy";

import Test from "./components/Test";
import LaPringamosaHome from "./hub/la-pringamosa/LaPringamosaHome";
import Loretta3Home from "./hub/loretta3/Loretta3Home";
import ArtesaniasArmadilloHome from "./hub/artesanias-armadillo/ArtesaniasArmadilloHome";
// import Web from "./components/Web";
// import Banner from "./features/web/Banner";
import "./site.css";
import AncestralHome from "./hub/ancestral-cafe-galeria/AncestralHome";

function App() {
  // const { company } = useSelector((state) => state.site);
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={<Loretta3Home />}
              // render={() => <Loretta3Home />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="test" element={<Test />} />
            <Route
              path="landing/la-pringamosa"
              // render={() => <LaPringamosaHome />}
              element={<LaPringamosaHome />}
            />

            <Route
              path="landing/ancestral-cafe-galeria"
              element={<AncestralHome />}
            />

            <Route
              path="landing/artesanias-armadillo"
              // render={() => <ArtesaniasArmadilloHome />}
              element={<ArtesaniasArmadilloHome />}
            />
            {/* 
            <Route
              path="landing/ashka-soluciones-financieras"
              element={<AshkaSolucionesFinancieras />}
            /> */}
            {/* <Route path="" element={<></>}/> */}
          </Routes>
        </BrowserRouter>
        {/* {whatsAppBt(company)} */}
      </Provider>
      {scrollTop()}
    </>
  );
}

export default App;
