import "./menuList.css";

const MenuList = ({ slogan, menu }) => {
  const formatCurrency = (value, locale) => {
    return value ? value.toLocaleString(locale) : "0";
  };

  return (
    <section id="menu" className="menu-section">
      <div className="header-menu">
        <h1>{slogan.one}</h1>
        <p>{slogan.two}</p>
      </div>

      {Object.keys(menu).map((categoria) => (
        <div className="menu-category" key={categoria}>
          <h2>{categoria}</h2>
          <ul>
            {menu[categoria].map((item, index) => (
              <li className="menu-item" key={index}>
                <span>{item.nombre}</span>
                <span>{item.descripcion ? item.descripcion : ""}</span>
                <span>{`$ ${formatCurrency(
                  item.precio?.COP,
                  "es-CO"
                )} COP | $ ${formatCurrency(
                  item.precio?.USD,
                  "en-US"
                )} USD`}</span>

                {/* <span>{`$ ${item.precio.COP.toLocaleString("es-CO")} COP | $ 
                 ${item.precio.USD.toLocaleString("en-US")} USD`}</span> */}
              </li>
            ))}
          </ul>
        </div>
      ))}

      {/* {drawMenu()} */}
    </section>
  );
};

export default MenuList;
