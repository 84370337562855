import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";

export const fetch = createAsyncThunk(
  `${siteConstants.LORETTA3}/fecth`,
  async (payload) => {
    return [];
  }
);

const initialState = {
  id: "loretta3",
  name: "Loretta3 | Soluciones IT",
  type: "IT Company",
  title: "Desarrollo Web y Soluciones IT a Medida",
  seo: {
    title: "Loretta3 | Desarrollo Web y Transformación Digital",
    description:
      "Loretta3 transforma negocios con soluciones IT personalizadas: desarrollo web, software a medida, y consultoría en transformación digital. Aumenta tu competitividad con servicios tecnológicos innovadores.",
    keywords:
      "Loretta3, desarrollo web, creación de páginas web, desarrollo de software, soluciones IT, transformación digital, presencia digital, SEO, marketing digital, Kit Digital, automatización empresarial, páginas web Bogotá, consultoría IT, negocios digitales, Loretta Business Hub, software personalizado",
    url: "https://loretta3.com",
    image: "https://loretta3.com/logo.png",
    robots: "index, follow",
    author: "Loretta3 Soluciones IT",
  },
  nav: {
    home: { href: "post-section", label: "Loretta3 | IT Solutions" },
    about: { href: "about-us", label: "Portafolio" },
    // products: { href: "products", label: "Servicios" },
    // contact: { href: "contact", label: "Contacto" },
  },
  post: {
    post: "loretta3",
    postOne: "IT",
    postTwo: "Solutions",
  },
  about: {
    "Presencia Digital": {
      desc: "Creación u optimización de la presencia digital, incluyendo página web, integración con redes sociales, SEO básico, geolocalización, campañas de marketing digital y gestión centralizada a través del portal de Loretta3.",
      description:
        "Enfocado en establecer o mejorar la visibilidad online de los negocios y gestionar su imagen digital desde un solo lugar.",
      features: [
        "Creación o renovación de la página web corporativa con diseño responsivo y certificado SSL.",
        "Integración con redes sociales (Facebook, Instagram, WhatsApp).",
        "SEO básico para optimizar la visibilidad en motores de búsqueda.",
        "Configuración en Google Maps, Waze y directorios digitales para geolocalización.",
        "Gestión de campañas de marketing digital personalizadas, con análisis y optimización de resultados.",
        "Administración centralizada a través del portal de Loretta3.",
      ],
      price: "$ 1'200.000 COP",
    },
    "Desarrollo a la Medida": {
      desc: "Soluciones personalizadas de software y aplicaciones para optimizar operaciones y procesos empresariales, con tecnología avanzada, automatización y escalabilidad.",
      description:
        "Desarrollo de software y aplicaciones personalizadas que optimizan procesos y potencian la eficiencia empresarial.",
      features: [
        "Software de gestión, automatización y monitoreo de operaciones.",
        "Aplicaciones móviles y web a la medida de las necesidades del cliente.",
        "Soluciones escalables, seguras y alineadas con los objetivos del negocio.",
        "Implementación ágil y soporte continuo.",
      ],
      price: "$ 5'000.000 COP",
    },
    "Consultoría IT	": {
      desc: "	Diagnóstico y asesoramiento estratégico en la transformación digital, implementación de sistemas, y soluciones tecnológicas a medida para mejorar la competitividad y eficiencia.",
      description:
        "Diagnóstico y asesoría estratégica para la transformación digital de las empresas.",
      features: [
        "Identificación de oportunidades de mejora en procesos digitales.",
        "Planificación e implementación de soluciones tecnológicas efectivas.",
        "Capacitación para el equipo interno en el uso de nuevas herramientas.",
        "Asesoramiento continuo para mantener la competitividad IT.",
      ],
      price: "$ 800.000 COP",
    },
  },
  customers: [
    "ancestral-cafe-galeria",
    "artesanias-armadillo",
    "ashka-soluciones-financieras",
  ],
  theme: {
    primaryColor: "#001c30",
    secondaryColor: "#230043",
    backgroundColor: "#e3e3d0",
    textColor: "#212529",
  },
  company: {
    name: "loretta3",
    whatsapp: "573244302025",
  },
  socialLinks: {
    float: {
      whatsapp:
        "https://api.whatsapp.com/send?phone=3244302025&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n",
    },
    footer: {
      facebook: "https://www.facebook.com/loretta3.it",
      instagram: "https://www.instagram.com/loretta3_it/",
      linkedin: "https://www.linkedin.com/company/loretta3",
      twitter: "https://x.com/LorenaSerr89731",
      youtube: "https://www.youtube.com/@Loretta3-it",
      tiktok: "https://www.tiktok.com/",
      google: "https://maps.app.goo.gl/YxW8QMWUKz1jmDFQ7",
      pinterest: "https://www.pinterest.com/loretta3_it",
      "map-location-dot": "https://maps.app.goo.gl/YxW8QMWUKz1jmDFQ7",
    },
  },

  // themeId: "theme_loretta3",
  // companyId: "company_loretta3",
  // socialLinksId: "social_loretta3",
  catalogId: "catalog_loretta3",

  customers: [
    {
      name: "Ashka Soluciones Financieras",
      title: "Ashka Soluciones Financieras - Créditos para Pensionados",
      description:
        "Encuentra la mejor opción de crédito para pensionados y empleados públicos con Ashka Soluciones Informáticas. Asesoría personalizada y la mejor oferta en créditos.",
      keywords:
        "créditos hipotecarios, créditos libranza, financiación, pensionados, empleados públicos",
      url: "https://loretta3.com/landing/ashka-soluciones-financieras",
      image: "https://loretta3.com/images/cust/ashka.png",
      robots: "index, follow",
      author: "Loretta3",
    },
    {
      name: "Ancestral Café Galería",
      title: "Ancestral Café Galería - Café, Postres y Arte en Zipaquirá",
      description:
        "Visita Ancestral Café Galería en el centro histórico de Zipaquirá y disfruta de nuestros postres, café, libros y arte en un ambiente único.",
      keywords:
        "café, postres, arte, libros, Zipaquirá, café en Zipaquirá, galería de arte",
      url: "https://loretta3.com/landing/ancestral-cafe-galeria",
      image: "https://loretta3.com/images/ancestral.jpg",
      robots: "index, follow",
      author: "Loretta3",
    },
    {
      name: "Artesanías Armadillo",
      title:
        "Artesanías Armadillo - Correaje y Cinturones de Cuero Hechos a Mano",
      description:
        "Descubre las correas de cuero personalizadas hechas a mano de Artesanías Armadillo. Calidad y durabilidad garantizadas.",
      keywords:
        "correas de cuero, cinturones, artesanía, productos personalizados, cuero legítimo",
      url: "https://loretta3.com/landing/artesanias-armadillo",
      image: "https://loretta3.com/images/armadillo.jpg",
      robots: "index, follow",
      author: "Loretta3",
    },
  ],
  message: null,
  loading: false,
  error: null,
};

const lorettaSlice = createSlice({
  name: "loretta",
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      });
  },
});

export const { init } = lorettaSlice.actions;
export default lorettaSlice.reducer;
