import { useEffect } from "react";
import styles from "./post.module.css";

const Post = ({ post }) => {
  useEffect(() => {
    document.body.classList.add(styles.post);
    return () => {
      document.body.classList.remove(styles.post);
    };
  }, []);

  return (
    <section id="post-section" className={styles.post}>
      <div className={styles.postInfo}>
        <span id="post-one" className={styles.postOne}>
          <h1>{post.post}</h1>
        </span>
        <span id="post-two" className={styles.postTwo}>
          <h2>
            <span className={styles.tittle}>{post.postOne}</span>&nbsp; -&nbsp;
            {post.postTwo}
          </h2>
        </span>
      </div>
    </section>
  );
};

export default Post;
