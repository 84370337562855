import { socialBt, whatsAppBt } from "../../assets/util/forms.util.js";

const SocialBts = ({ socialLinks }) => {
  const bottom = 50;
  return (
    <>
      {/* {socialBt()} */}
      {/* {whatsAppBt("3005165832")} */}
      {socialLinks &&
        Object.keys(socialLinks).map((key, i) => {
          {
            return socialBt({
              key: key,
              socialLink: socialLinks[key],
              bottom: bottom + i * 70,
            });
          }
        })}
    </>
  );
};

export default SocialBts;
